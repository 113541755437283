import React from 'react'

import SVGIcon from 'components/common/atoms/SVGIcon'
import HeartSVG from 'svgs/heart.svg'
import HeartOutlineSVG from 'svgs/heart-outline.svg'

import { useTheme } from 'emotion-theming'
import { css } from '@emotion/core'
import sizeGenerate from 'utils/styles/sizeGenerate'
import flexCenter from 'utils/styles/flexCenter'

import AuthenticatedLink from 'components/common/molecules/AuthenticatedLink'

type LoveButtonProps = {
  isLiked: boolean
  dataTestId?: string
  onClick?: (params?: never) => void
  kind?: 'card' | 'section' | 'nav' | 'icon'
}

const loveButtonStyle = css`
  ${flexCenter}
  cursor: pointer;
`

const loveButtonKindStyle = (theme: ThemeType, kind: LoveButtonProps['kind']) => {
  switch (kind) {
    case 'card':
      return css`
        border-radius: 100%;
        border: none;

        background: ${theme.gray.gray10};
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 2;

        ${sizeGenerate(24)}
        @media screen and (min-width: 768px) {
          ${sizeGenerate(32)}
        }
      `
    case 'section':
      return css`
        border: 1px solid ${theme.gray.gray40};
        border-radius: 4px;
        ${sizeGenerate(50)}
      `
    case 'nav':
      return css`
        border: 1px solid ${theme.gray.gray40};
        border-radius: 4px;
        ${sizeGenerate(40)}
      `
    case 'icon':
      return css`
        ${sizeGenerate(24)}
      `
    default:
      return null
  }
}

const LoveButton = ({ isLiked, onClick, dataTestId, kind = 'card' }: LoveButtonProps) => {
  const theme: ThemeType = useTheme()

  return (
    <AuthenticatedLink
      onClick={onClick}
      className="love-button"
      css={[loveButtonStyle, loveButtonKindStyle(theme, kind)]}
      dataTestId={dataTestId || ''}
    >
      <SVGIcon
        name="heart"
        size={kind === 'icon' ? 'md' : 'xs'}
        sizePC={kind === 'card' ? 'sm' : 'md'}
        color={isLiked ? theme.error.red40 : theme.gray.gray50}
      >
        {kind !== 'card' && !isLiked ? <HeartOutlineSVG /> : <HeartSVG />}
      </SVGIcon>
    </AuthenticatedLink>
  )
}

export default LoveButton

import React from 'react'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import Typography from 'components/common/atoms/Typography'

const backdropStyle = (theme: ThemeType) => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: ${theme.neutral.white};

  .loading-screen {
    &__container {
      padding: 80% 0;
      height: 100%;
      text-align: center;
      @media screen and (min-width: 768px) {
        padding: 25% 0;
      }
      p {
        margin-top: 8px;
        animation: loading-text ease-in-out 1.2s infinite;
        @keyframes loading-text {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
  }
`

const LoadingScreen = () => {
  const theme: ThemeType = useTheme()
  return (
    <div css={backdropStyle(theme)}>
      <div className="loading-screen__container">
        <img
          src="/next-assets/images/logo/quipper-campus-logo.svg"
          alt="Info Kampus Terlengkap dan Berkualitas | Quipper Campus"
        />
        <Typography variant="paragraph" level="1" color={theme.gray.gray50} tagName="p">
          Loading...
        </Typography>
      </div>
    </div>
  )
}

export default LoadingScreen

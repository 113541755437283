import React, { FC, useContext, ReactNode } from 'react'
import { ProfileCtx, ActionType } from 'components/utils/ProfileProvider'
import { useRouter } from 'next/router'

interface AuthenticatedLinkProps {
  className?: string
  dataTestId?: string
  onClick?: () => void
  children: ReactNode
  skipAuth?: boolean
}

const AuthenticatedLink: FC<AuthenticatedLinkProps> = ({
  children,
  className,
  dataTestId,
  onClick,
  skipAuth = false,
}) => {
  const { state: profileState, dispatch, incompleteVpMeta } = useContext(ProfileCtx)
  const router = useRouter()

  const handleClick = () => {
    if (!skipAuth) {
      if (profileState.authStatus === 'authorized') {
        if (!(profileState.agreedToTerms && profileState.agreedToPolicy)) {
          dispatch({ type: ActionType.SHOW_TERMS_MODAL })
        } else if (profileState.hasVpMeta) {
          onClick && onClick()
        } else if (!profileState.hasVpMeta || incompleteVpMeta) {
          window.location.href = `/signup/application?successUrl=${encodeURIComponent(window.location.pathname)}`
        }
      } else {
        router.push(`/signin?isLead=true&successUrl=${encodeURIComponent(window.location.pathname)}`)
      }
    } else {
      onClick && onClick()
    }
  }
  return (
    <div onClick={handleClick} onKeyDown={handleClick}  className={className} data-testid={dataTestId}>
      {children}
    </div>
  )
}

export default AuthenticatedLink

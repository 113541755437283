import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

type CardWrapperProps = {
  variant?: 'default' | 'mini'
  children: ReactNode
}

const cardWrapperStyle = (theme: ThemeType) => css`
  position: relative;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(26, 52, 79, 0.1);
  background-color: ${theme.neutral.white};
  transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    &:hover {
      box-shadow: 0 2px 8px 0 rgba(26, 52, 79, 0.4);
      .campus-card-image:after {
        display: none;
      }
    }
  }
`

const miniCardWrapperStyle = (theme: ThemeType) => css`
  height: 56px;
  border-radius: 4px;
  border: solid 1px ${theme.gray.gray20};
  background-color: ${theme.neutral.white};
`

const CardWrapper: FC<CardWrapperProps> = ({ children, variant = 'default' }) => {
  const theme: ThemeType = useTheme()
  return (
    <div className="campus-card" css={variant === 'default' ? cardWrapperStyle(theme) : miniCardWrapperStyle(theme)}>
      {children}
    </div>
  )
}

export default CardWrapper

import React from 'react'

import Typography from 'components/common/atoms/Typography'
import Ellipsis from 'components/common/atoms/Ellipsis'
import { useTheme } from 'emotion-theming'

type CardDescriptionProps = {
  description: string
  descLine?: number
  useMarkdown?: boolean
}

const CardDescription = ({ description, descLine, useMarkdown = false }: CardDescriptionProps) => {
  const theme: ThemeType = useTheme()
  return (
    <Typography tagName="div" variant="paragraph" level="2">
      <Ellipsis text={description} color={theme.gray.gray40} clamp={descLine} useMarkdown={useMarkdown} />
    </Typography>
  )
}

export default CardDescription

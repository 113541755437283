import React from 'react'
import Relazsizes from 'relazsizes'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import Typography from 'components/common/atoms/Typography'
import Badge from 'components/common/atoms/Badge'

type CardImageProps = {
  imageUrl?: string
  title: string
  label?: string
  labelColor?: string
  labelBackground?: string
  caption?: string
  isLikeable?: boolean
  isLiked?: boolean
}

const cardImageStyle = (theme: ThemeType) => css`
  position: relative;
  height: 90px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${theme.gray.gray70};
    opacity: 0.3;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .badge,
  .campus-card-caption {
    position: absolute;
    left: 8px;
    bottom: 8px;
    z-index: 2;
  }
  @media screen and (min-width: 768px) {
    height: 128px;
  }
`

const CardImage = ({ imageUrl, title, label, labelBackground, labelColor, caption }: CardImageProps) => {
  const theme: ThemeType = useTheme()
  return (
    <div className="campus-card-image" css={cardImageStyle(theme)}>
      <Relazsizes
        src="/next-assets/images/skeleton.svg"
        dataSrc={imageUrl || '/next-assets/images/placeholder_school_card.jpg'}
        alt={title}
        css={css`
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        `}
      />
      {caption && (
        <div className="campus-card-caption">
          <Typography tagName="div" variant="body" level="2" fontWeight="bold" color={theme.neutral.white}>
            {caption}
          </Typography>
        </div>
      )}
      {label && <Badge background={labelBackground} text={label} color={labelColor} />}
    </div>
  )
}

export default CardImage

import React, { FC } from 'react'
import { css } from '@emotion/core'

const CardFlexItemWrapper: FC = ({ children }) => (
  <div
    className="campus-flex-item"
    css={css`
      min-width: 150px;
      width: 150px;
      max-height: 259px;
      &:not(:last-of-type) {
        margin-right: 8px;
      }
      &:first-of-type {
        margin-left: 16px;
      }
      &:last-of-type .campus-card {
        width: inherit;
        display: inline-block;
        margin-right: 16px;
      }
      @media screen and (min-width: 768px) {
        min-width: 225px;
        width: 225px;
        max-height: none;
        &:not(:last-of-type) {
          margin-right: 16px;
        }
        &:first-of-type {
          margin-left: 0;
        }
      }
    `}
  >
    {children}
  </div>
)

export default CardFlexItemWrapper

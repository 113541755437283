import React, { ReactNode } from 'react'

import CashSvg from 'svgs/major-cash.svg'
import EarthSvg from 'svgs/major-earth.svg'
import MicrophoneSvg from 'svgs/major-microphone.svg'
import LeafSvg from 'svgs/major-leaf.svg'
import LaptopSvg from 'svgs/major-laptop.svg'
import AtomSvg from 'svgs/major-atom.svg'
import BuildingSvg from 'svgs/major-building.svg'
import ForestSvg from 'svgs/major-forest.svg'
import HatSvg from 'svgs/major-hat.svg'
import PaintSvg from 'svgs/major-paint.svg'
import GraduationcapSvg from 'svgs/major-graduation.svg'
import BookSvg from 'svgs/major-book.svg'
import UniformSvg from 'svgs/major-uniform.svg'
import BallSvg from 'svgs/major-ball.svg'
import WaveSvg from 'svgs/major-wave.svg'
import PalmTreeSvg from 'svgs/major-palmtree.svg'

type SVGProps = {
  [key: string]: ReactNode
}

const getMajorIcon = (name?: string) => {
  const svgs: SVGProps = {
    'major-cash': <CashSvg />,
    'major-book': <BookSvg />,
    'major-microphone': <MicrophoneSvg />,
    'major-uniform': <UniformSvg />,
    'major-leaf': <LeafSvg />,
    'major-earth': <EarthSvg />,
    'major-atom': <AtomSvg />,
    'major-laptop': <LaptopSvg />,
    'major-building': <BuildingSvg />,
    'major-forest': <ForestSvg />,
    'major-hat': <HatSvg />,
    'major-paint': <PaintSvg />,
    'major-graduationcap': <GraduationcapSvg />,
    'major-ball': <BallSvg />,
    'major-wave': <WaveSvg />,
    'major-palmtree': <PalmTreeSvg />,
  }
  if (name) {
    return svgs[name]
  }
  return null
}

export default getMajorIcon

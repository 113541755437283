import React from 'react'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

import Typography from 'components/common/atoms/Typography'

type TextLinkProps = {
  color?: string
  href?: string
  className?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  text: string
  linkProps?: LooseObj
}

const TextLink = ({ color, className, text, href, onClick, linkProps }: TextLinkProps) => {
  const theme: ThemeType = useTheme()
  return (
    <Typography
      tagName="div"
      variant="heading"
      level="1"
      color={color || theme.campus.aqua30}
      className={className}
      css={css`
        display: inline-block;
        &:hover {
          color: ${theme.campus.aqua20};
        }
        &:active {
          color: ${theme.campus.aqua50};
        }
      `}
    >
      <a
        href={href || '#'}
        onClick={onClick}
        className={className}
        css={css`
          color: inherit;
          font-size: 12px;
          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
        `}
        {...linkProps}
      >
        {text}
      </a>
    </Typography>
  )
}

export default TextLink

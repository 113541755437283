import React, { ReactNode, FC } from 'react'

import CardWrapper from 'components/cards/atoms/CardWrapper/Index'
import CardContent from 'components/cards/atoms/CardContent'

import CardImage from 'components/cards/atoms/CardImage'
import CardDescription from 'components/cards/molecules/CardDescription'
import CardTitle from 'components/cards/molecules/CardTitle'
import LoveButton from 'components/common/atoms/LoveButton'
import AuthenticatedLink from 'components/common/molecules/AuthenticatedLink'
import { useRouter } from 'next/router'

type CampusCardProps = {
  label?: string
  labelBackground?: string
  labelColor?: string
  imageUrl?: string
  logoUrl?: string
  title: string
  description: string
  isLiked?: boolean
  isLikeable?: boolean
  caption?: string
  icon?: string
  ctaButton?: ReactNode
  url?: string
  linkProps?: LooseObj
  onClick?: () => void
  likeAction?: (params?: never) => void
  useMarkdown?: boolean
  dataTestId?: string
  kind?: 'major'
  authRequired?: boolean
  customClick?: boolean
}

type WrapperLinkProps = {
  authRequired: boolean
  children: ReactNode
  url?: string
  onClick?: () => void
  dataTestId?: string
  linkProps?: LooseObj
  customClick?: boolean
}

const WrapperLink: FC<WrapperLinkProps> = ({
  authRequired,
  children,
  url,
  onClick,
  dataTestId,
  linkProps,
  customClick,
}) => {
  const router = useRouter()
  if (authRequired) {
    const handleClick = () => {
      onClick && onClick()
      if (!customClick) {
        router.push(url || '#')
      }
    }
    return (
      <AuthenticatedLink onClick={handleClick} dataTestId={dataTestId}>
        {children}
      </AuthenticatedLink>
    )
  }
  return (
    <a href={url} {...linkProps} onClick={onClick} data-testid={dataTestId}>
      {children}
    </a>
  )
}

const CampusCard = ({
  authRequired = false,
  imageUrl,
  title,
  description,
  label,
  labelBackground,
  labelColor,
  logoUrl,
  isLiked,
  isLikeable,
  caption,
  icon,
  ctaButton,
  url,
  linkProps,
  likeAction,
  onClick,
  useMarkdown = false,
  dataTestId,
  kind,
  customClick = false,
}: CampusCardProps) => {
  return (
    <CardWrapper>
      {isLikeable && (
        <LoveButton isLiked={!!isLiked} onClick={likeAction} dataTestId={dataTestId?.replace('-', '-love-btn-')} />
      )}
      <WrapperLink
        authRequired={authRequired}
        url={url}
        {...linkProps}
        onClick={onClick}
        dataTestId={dataTestId?.replace('-', '-card-')}
        customClick={customClick}
      >
        <CardImage
          imageUrl={imageUrl}
          title={title}
          label={label}
          labelBackground={labelBackground}
          labelColor={labelColor}
          isLiked={isLiked}
          isLikeable={isLikeable}
          caption={caption}
        />
        <CardContent>
          <CardTitle title={title} logoUrl={logoUrl} icon={icon} kind={kind} />
          <CardDescription description={description} descLine={ctaButton ? 3 : 5} useMarkdown={useMarkdown} />
          {ctaButton}
        </CardContent>
      </WrapperLink>
    </CardWrapper>
  )
}

export default CampusCard

import React from 'react'
import Typography from 'components/common/atoms/Typography'
import Ellipsis from 'components/common/atoms/Ellipsis'

import Relazsizes from 'relazsizes'
import { css } from '@emotion/core'
import SVGIcon from 'components/common/atoms/SVGIcon'
import { useTheme } from 'emotion-theming'
import flexCenter from 'utils/styles/flexCenter'
import getMajorIcon from 'components/utils/getMajorIcon'

type CardTitleProps = {
  title: string
  logoUrl?: string
  icon?: string
  kind?: 'major'
}

const cardTitleStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  height: 36px;
  .campus-card-logo {
    min-width: 28px;
    width: 28px;
    height: 28px;
    border: solid 1px #ebeef3;
    background-color: #ffffff;
    border-radius: 4px;
    margin-right: 4px;
    ${flexCenter}
  }

  .ellipsis {
    font-size: 12px !important;
    line-height: 1.5 !important;
  }
  .campus-markdown-content {
    margin: 0;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 12px;
    .campus-card-logo {
      min-width: 40px;
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }

    .ellipsis {
      font-size: 16px !important;
    }
  }
`

const CardTitle = ({ title, logoUrl, icon, kind }: CardTitleProps) => {
  const theme: ThemeType = useTheme()

  const getLogo = () => {
    if (logoUrl) {
      return (
        <Relazsizes
          src="/next-assets/images/skeleton.svg"
          dataSrc={logoUrl}
          alt={title}
          css={css`
            width: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%;
          `}
        />
      )
    }
    if (kind === 'major') {
      const majorIcon = icon || 'major'
      return (
        <SVGIcon name={majorIcon} color={theme.secondary.darkblue40} size="xs" sizePC="md">
          {getMajorIcon(majorIcon)}
        </SVGIcon>
      )
    } else if (icon) {
      return <SVGIcon name={icon} color={theme.secondary.darkblue40} size="xs" sizePC="md" />
    }
    return null
  }
  return (
    <div className="campus-card-title" css={cardTitleStyle}>
      {(logoUrl || icon) && <div className="campus-card-logo">{getLogo()}</div>}
      <Typography tagName="div" variant="heading" level="1" className="campus-card-title-text">
        <Ellipsis clamp={2} text={title} useMarkdown />
      </Typography>
    </div>
  )
}

export default CardTitle

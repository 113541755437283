import React, { FC } from 'react'
import { css } from '@emotion/core'

const CardContent: FC = ({ children }) => (
  <div
    className="card-content"
    css={css`
      padding: 12px;
      @media screen and (min-width: 768px) {
        padding: 16px;
      }
    `}
  >
    {children}
  </div>
)

export default CardContent
